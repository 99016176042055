import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { Button, Intent } from '@blueprintjs/core';
import { Tooltip2 as Tooltip } from '@blueprintjs/popover2';
import { selectSession } from 'selectors';
import { endpoint } from '../../app/api';

const messages = defineMessages({
  download: {
    id: 'events.download',
    defaultMessage: 'You must sign in to upload your own data.',
  },
});

class EventsDownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.download = this.download.bind(this);
  }

  download() {
    const { period } = this.props;

    endpoint.get('events/download?period=' + period).then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw response.data;
      }
    }).then((data) => {
      const contentType = 'text/csv';
      const csvFile = new Blob([data], {type: contentType});
      
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(csvFile);
      a.download = `rbindex_${period}.csv`;
      a.target = "_blank";
      a.click();
    }).catch(() => {
      this.setState({
        isMaxLimitOpen: true
      });
    })

  }

  render() {
    const { icon, intl, session, text } = this.props;
    const buttonDisabled = !session.loggedIn;

    return (
      <>
        <Tooltip
          content={intl.formatMessage(messages.download)}
          disabled={!buttonDisabled}
        >
          <Button
            onClick={this.download}
            icon={icon}
            text={text}
            intent={Intent.PRIMARY}
            disabled={buttonDisabled}
          />
        </Tooltip>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ session: selectSession(state) });

EventsDownloadButton = connect(mapStateToProps)(EventsDownloadButton);
EventsDownloadButton = injectIntl(EventsDownloadButton);
export default EventsDownloadButton;
