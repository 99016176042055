import React, { Component } from 'react';
import { Alert, Intent } from '@blueprintjs/core';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withRouter from 'app/withRouter';
import { deleteUser } from '../../actions';

const messages = defineMessages({
  button_confirm: {
    id: 'user.delete.confirm',
    defaultMessage: 'Delete'
  },
  button_cancel: {
    id: 'user.delete.cancel',
    defaultMessage: 'Cancel'
  }
});

class UserDeleteDialog extends Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
  }

  async onDelete() {
    const { user, navigate } = this.props;
    await this.props.deleteUser(user);
    navigate(0);
  }

  render() {
    const { user, intl } = this.props;
    return (
      <Alert
        isOpen={this.props.isOpen}
        icon='trash'
        intent={Intent.DANGER}
        cancelButtonText={intl.formatMessage(messages.button_cancel)}
        confirmButtonText={intl.formatMessage(messages.button_confirm)}
        onCancel={this.props.toggleDialog}
        onConfirm={this.onDelete}
      >
        <FormattedMessage
          id='user.delete.question'
          defaultMessage='Are you sure you want to remove {userLabel}?'
          values={{
            userLabel: user.name
          }}
        />
      </Alert>
    );
  }
}

const mapDispatchToProps = { deleteUser };

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  injectIntl
)(UserDeleteDialog);
