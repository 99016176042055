import React, { PureComponent } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Button, Classes, H4, Icon } from '@blueprintjs/core';
import { Count, Date, Skeleton } from 'components/common';
import { Tooltip2 as Tooltip } from '@blueprintjs/popover2/lib/esm/tooltip2';
import UserDeleteDialog from '../../dialogs/UserDeleteDialog/UserDeleteDialog';
import { selectCurrentRole } from '../../selectors';
import { connect } from 'react-redux';
import UserResetPasswordDialog from '../../dialogs/UserResetPasswordDialog/UserResetPasswordDialog';
import UserEditAdminDialog from '../../dialogs/UserEditAdminDialog/UserEditAdminDialog';

const messages = defineMessages({
  delete_button: {
    id: 'user.index.delete',
    defaultMessage: 'Remove User'
  },
  reset_pwd_button: {
    id: 'user.index.reset_pwd',
    defaultMessage: 'Create new password'
  },
  promote_admin_button: {
    id: 'user.index.promote_admin',
    defaultMessage: 'Promote to Administrator'
  },
  revoke_admin_button: {
    id: 'user.index.revoke_admin',
    defaultMessage: 'Revoke administrator role from the user'
  }
});

class UserIndexItem extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {};

    this.toggleDialog = this.toggleDialog.bind(this);
  }

  toggleDialog(name) {
    const isOpen = this.state[name];
    this.setState({ [name]: !!!isOpen });
  }

  renderSkeleton = () => (
    <li className='index-item'>
      <div className='index-item__count'>
        <Count className={Classes.INTENT_PRIMARY} full isPending />
      </div>
      <H4 className='index-item__title'>
        <Skeleton.Text type='span' length={20} />
      </H4>
      <Skeleton.Text className='index-item__summary' type='p' length={200} />
      <p className='index-item__details'>
        <Skeleton.Text
          className='index-item__details__item'
          type='span'
          length={20}
        />
        <Skeleton.Text
          className='index-item__details__item'
          type='span'
          length={20}
        />
        <Skeleton.Text
          className='index-item__details__item'
          type='span'
          length={20}
        />
      </p>
    </li>
  );

  render() {
    const { user, isPending, intl, role } = this.props;

    if (isPending || !user.id) {
      return this.renderSkeleton();
    }

    return (
      <li className={`index-item ${user.is_internal ? 'internal' : 'external'}`} key={user.id}>
        {role.id !== user.id && <div className='index-item__actions'>
          <Tooltip content={intl.formatMessage(messages.reset_pwd_button)}>
            <Button
              onClick={() => this.toggleDialog('isResetPasswordOpen')}
              icon='key'
              minimal
              small
            >
              <FormattedMessage
                id='user.index.reset_pwd.button'
                defaultMessage='Nova senha'
              />
            </Button>
          </Tooltip>
          { !user.is_admin ? <Tooltip content={intl.formatMessage(messages.promote_admin_button)}>
            <Button
              onClick={() => this.toggleDialog('isEditAdminOpen')}
              icon='badge'
              minimal
              small
            >
              <FormattedMessage
                id='user.index.promote_admin.button'
                defaultMessage='Promote to Admin'
              />
            </Button>
          </Tooltip> : <Tooltip content={intl.formatMessage(messages.revoke_admin_button)}>
            <Button
              onClick={() => this.toggleDialog('isEditAdminOpen')}
              icon='following'
              minimal
              small
            >
              <FormattedMessage
                id='user.index.revoke_admin.button'
                defaultMessage='Revoke Admin Role'
              />
            </Button>
          </Tooltip> }
          <Tooltip content={intl.formatMessage(messages.delete_button)}>
            <Button
              onClick={() => this.toggleDialog('isDeleteOpen')}
              icon='trash'
              minimal
              small
            >
              <FormattedMessage
                id='user.index.delete.button'
                defaultMessage='Remove'
              />
            </Button>
          </Tooltip>
        </div>}
        <H4 className='index-item__title'>
          {user.label}
        </H4>
        <p className='index-item__details'>
          <span className='index-item__details__item'>
            <Icon icon='time' />
            <FormattedMessage
              id='user.created_at'
              defaultMessage='Creation date {date}'
              values={{
                date: <Date value={user.created_at} />
              }}
            />
          </span>
          {user.is_admin && <span className='index-item__details__item admin'>
            <Icon icon='shield' />
            <FormattedMessage
              id='user.type.admin'
              defaultMessage='Admin'
            />
          </span>}
          {user.is_internal ? <span className='index-item__details__item internal'>
            <Icon icon='globe' />
            <FormattedMessage
              id='user.type.rb'
              defaultMessage='Repórter Brasil'
            />
          </span> : <span className='index-item__details__item external'>
            <Icon icon='walk' />
            <FormattedMessage
              id='user.type.external'
              defaultMessage='External'
            />
          </span>}
        </p>
        <UserDeleteDialog
          isOpen={!!this.state.isDeleteOpen}
          toggleDialog={() => this.toggleDialog('isDeleteOpen')}
          user={user}
        />
        <UserResetPasswordDialog
          isOpen={!!this.state.isResetPasswordOpen}
          toggleDialog={() => this.toggleDialog('isResetPasswordOpen')}
          user={user}
        />
        <UserEditAdminDialog
          isOpen={!!this.state.isEditAdminOpen}
          toggleDialog={() => this.toggleDialog('isEditAdminOpen')}
          user={user}
        />
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  role: selectCurrentRole(state)
});

UserIndexItem = connect(mapStateToProps)(UserIndexItem);
UserIndexItem = injectIntl(UserIndexItem);
export default UserIndexItem;
