import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {defineMessages, injectIntl} from 'react-intl';

import withRouter from 'app/withRouter';
import {selectSession} from 'selectors';
import Screen from 'components/Screen/Screen';

import './LoginScreen.scss';

const messages = defineMessages({
    title: {
        id: 'home.title',
        defaultMessage: 'Find public records and leaks',
    },
    description: {
        id: 'home.stats.title',
        defaultMessage: 'Public access temporarily disabled',
    },
});

export class LoginScreen extends Component {
    render() {
        const {intl, session} = this.props;

        if (session.loggedIn) {
            return <Navigate to="/" replace/>;
        }

        const navbar = document.getElementsByClassName('Navbar')[0];
        if (navbar) {
            navbar.style = "display: none;";
        }

        return (
            <Screen
                title={intl.formatMessage(messages.title)}
                description={intl.formatMessage(messages.description)}
                requireAuth={true}
                className={'LoginScreen'}>
            </Screen>
        );
    }
}

const mapStateToProps = (state) => ({
    session: selectSession(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps, {}),
    injectIntl
)(LoginScreen);
