import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  AnchorButton,
  Button,
  Callout,
  Checkbox,
  Classes,
  Dialog,
  Icon,
  Intent,
} from '@blueprintjs/core';
import { Tooltip2 as Tooltip } from '@blueprintjs/popover2';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { dispatchSetConfigValue } from 'actions/configActions';

import './DownloadButton.scss';

const messages = defineMessages({
  download: {
    id: 'document.download',
    defaultMessage: 'Download',
  },
  mode_download: {
    id: 'document.download.tooltip',
    defaultMessage: 'Download the original document',
  },
  button_cancel: {
    id: 'document.download.cancel',
    defaultMessage: 'Cancel',
  },
  button_ok: {
    id: 'document.download.ok',
    defaultMessage: 'OK',
  },
  button_confirm: {
    id: 'document.download.confirm',
    defaultMessage: 'Download',
  },
  checkbox_label: {
    id: 'document.download.dont_warn',
    defaultMessage:
      "Don't warn me in the future when downloading source documents",
  },
});

class DownloadButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { checkboxChecked: false, isOpen: false, isErrorDialogOpen: false, isDownloading: false, errorMessage: '' };

    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.toggleDialog = this.toggleDialog.bind(this);
    this.toggleMaxLimitDialog = this.toggleMaxLimitDialog.bind(this);
    this.download = this.download.bind(this);
  }

  download(file) {
    if (!this.state.isDownloading) {
      this.setState(() => ({
        isDownloading: true,
      }));
      fetch(file).then((response) => {
        if (response.status === 200) {
          return response.blob();
        } else {
          throw response.json();
        }
      }).then((data) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        const fileNameProperty = this.props.document.getProperties().filter((prop) => prop.name === 'fileName')[0];
        a.download = this.props.document.getProperty(fileNameProperty);
        a.target = "_blank";
        a.click();
      }).catch((e) => {
        if (e instanceof Promise) {
          e.then((response) => {
            if (response.status === 'error') {
              this.setState({
                isErrorDialogOpen: true,
                errorMessage: response.message
              });
            } else {
              this.setState({
                isErrorDialogOpen: true,
                errorMessage: response
              });
            }
          });
        } else {
          this.setState({
            isErrorDialogOpen: true,
            errorMessage: `Erro inesperado: ${e}`
          });
        }
      }).finally(() => {
        this.setState(() => ({
          isDownloading: false,
        }));
      });
    }
  }

  toggleDialog() {
    this.setState(({ checkboxChecked, isOpen }) => {
      if (checkboxChecked) {
        this.props.dispatchSetConfigValue({ dontWarnOnDownload: true });
      }
      return {
        isOpen: !isOpen,
      };
    });
  }

  toggleMaxLimitDialog() {
    this.setState(({ isErrorDialogOpen }) => {
      return {
        isErrorDialogOpen: !isErrorDialogOpen,
      };
    });
  }

  toggleCheckbox() {
    this.setState(({ checkboxChecked }) => ({
      checkboxChecked: !checkboxChecked,
    }));
  }

  render() {
    const { intl, document, dontWarnOnDownload } = this.props;
    const { checkboxChecked, isOpen, isErrorDialogOpen, isDownloading, errorMessage } = this.state;

    const link_file = document?.links?.file || document?.links?.csv;

    if (!link_file) {
      return null;
    }

    // Link para log de evento de download
    const file = link_file.replace("/archive", "/archive/original")

    const errorDialog = <Dialog isOpen={isErrorDialogOpen} className={Classes.ALERT}>
      <div className={Classes.ALERT_BODY}>
        <Icon icon="warning-sign" iconSize={40} intent={Intent.DANGER} />
        <div className={Classes.ALERT_CONTENTS}>
          <p>
            <FormattedMessage
              id={errorMessage}
              defaultMessage={`
                <bold>An error has occured.</bold>
                {br}{br}
                ${errorMessage}
                {br}{br}
                Contact the System Administrators to solve this issue.
              `}
              values={{
                bold: (...chunks) => <b>{chunks}</b>,
                br: <br />,
              }}
            />
          </p>
        </div>
      </div>
      <div className={Classes.ALERT_FOOTER}>
        <Button
          onClick={this.toggleMaxLimitDialog}
          text={intl.formatMessage(messages.button_ok)}
        />
      </div>
    </Dialog>;

    if (dontWarnOnDownload) {
      return (
        <>
          <Tooltip content={intl.formatMessage(messages.mode_download)}>
            <AnchorButton
              icon='download'
              target='_blank'
              className={`DownloadButton ${isDownloading ? 'disabled' : ''}`}
              rel='nofollow noopener noreferrer'
              text={intl.formatMessage(messages.download)}
              onClick={() => this.download(file)}
            />
          </Tooltip>
          {errorDialog}
        </>
      );

    }
    return (
      <>
        <Tooltip content={intl.formatMessage(messages.mode_download)}>
          <Button
            icon="download"
            text={intl.formatMessage(messages.download)}
            onClick={this.toggleDialog}
          />
        </Tooltip>
        <Dialog isOpen={isOpen} className={Classes.ALERT}>
          <div className={Classes.ALERT_BODY}>
            <Icon icon="warning-sign" iconSize={40} intent={Intent.DANGER} />
            <div className={Classes.ALERT_CONTENTS}>
              <p>
                <FormattedMessage
                  id="document.download.warning"
                  defaultMessage={`
                    <bold>You’re about to download a source file.</bold>
                    {br}{br}
                    Source files can contain viruses and code that notify the originator when you open them.
                    {br}{br}
                    For sensitive data, we recommend only opening source files on a computer that is permanently disconnected from the internet.
                  `}
                  values={{
                    bold: (...chunks) => <b>{chunks}</b>,
                    br: <br />,
                  }}
                />
              </p>
              <Callout className="DownloadButton__checkbox-container">
                <Checkbox
                  checked={checkboxChecked}
                  label={intl.formatMessage(messages.checkbox_label)}
                  onChange={this.toggleCheckbox}
                />
              </Callout>
            </div>
          </div>
          <div className={Classes.ALERT_FOOTER}>
            <AnchorButton
              icon="download"
              target="_blank"
              className={`DownloadButton ${isDownloading ? 'disabled' : ''}`}
              rel="nofollow noopener noreferrer"
              text={intl.formatMessage(messages.button_confirm)}
              intent={Intent.DANGER}
              onClick={() => {
                this.download(file);
                this.toggleDialog();
              }}
            />
            <Button
              onClick={this.toggleDialog}
              text={intl.formatMessage(messages.button_cancel)}
            />
          </div>
        </Dialog>
        {errorDialog}
      </>
    );
  }
}
const mapStateToProps = ({ config }) => ({
  dontWarnOnDownload: config.dontWarnOnDownload,
});
const mapDispatchToProps = { dispatchSetConfigValue };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(DownloadButton);
