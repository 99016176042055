import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Navigate } from 'react-router-dom';
import queryString from 'query-string';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import withRouter from 'app/withRouter';
import {
  AnimatedCount,
  SearchBox,
  Statistics,
} from 'components/common';
import { fetchStatistics } from 'actions/index';
import { selectCurrentRole, selectMetadata, selectSession, selectStatistics } from 'selectors';
import Screen from 'components/Screen/Screen';
import wordList from 'util/wordList';

import './HomeScreen.scss';

const messages = defineMessages({
  title: {
    id: 'home.title',
    defaultMessage: 'Find public records and leaks',
  },
  access_disabled: {
    id: 'home.access_disabled',
    defaultMessage: 'Public access temporarily disabled',
  },
  placeholder: {
    id: 'home.placeholder',
    defaultMessage: 'Try searching: {samples}',
  },
  count_investigations: {
    id: 'home.counts.investigations',
    defaultMessage: 'Pastas',
  },
  count_documents: {
    id: 'home.counts.documents',
    defaultMessage: 'Arquivos',
  },
});

export class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // for now, always load
    this.props.fetchStatistics();
  }

  onSubmit(queryText) {
    const { navigate } = this.props;
    navigate({
      pathname: '/search',
      search: queryString.stringify({
        q: queryText,
      }),
    });
  }

  render() {
    const { role, intl, metadata, statistics = {}, session } = this.props;
    const appHomePage = metadata.pages.find((page) => page.home);

    if (!session.loggedIn) {
      return <Navigate to="/login" replace />;
    }

    const { description, samples, title } = appHomePage;
    const samplesList = wordList(samples, ', ').join('');

    return (
      <Screen
        title={intl.formatMessage(messages.title)}
        description={description}
        requireSession={true}
      >
        <div className="HomeScreen">
          <section className="HomeScreen__section title-section">
            <div className="HomeScreen__section__content">
              <h1 className="HomeScreen__app-title">{title}</h1>
              {description && (
                <p className="HomeScreen__description">{description}</p>
              )}
              <div className="HomeScreen__search">
                <SearchBox
                  onSearch={this.onSubmit}
                  placeholder={intl.formatMessage(messages.placeholder, {
                    samples: samplesList,
                  })}
                  inputProps={{ large: true, autoFocus: true }}
                />
                <div className="HomeScreen__thirds">
                    { statistics?.rb?.folders && <AnimatedCount
                    count={statistics.rb.folders}
                    isPending={statistics.isPending}
                    label={intl.formatMessage(messages.count_investigations)}
                  /> }
                    { statistics?.rb?.documents && <AnimatedCount
                    count={statistics.rb.documents}
                    isPending={statistics.isPending}
                    label={intl.formatMessage(messages.count_documents)}
                  /> }
                </div>
              </div>
            </div>
          </section>
          <section className="HomeScreen__section">
            <div className="HomeScreen__section__content">
              <h1 className="HomeScreen__title">Sobre o RBIndex</h1>
              <div className="HomeScreen__halves">
                <div>
                  <img src="/static/logomarca.png" width="560" height="315" />
                </div>
                <div>
                  <div className="HomeScreen__paragraph">
                    <p>O RBIndex é a biblioteca de dados da Repórter Brasil.</p>
                  </div>
                  <div className="HomeScreen__paragraph">
                    <p>A plataforma armazena documentos produzidos e coletados pela organização com o objetivo de facilitar a pesquisa e o compartilhamento de dados.</p>
                  </div>
                  { role.is_internal && <a className='bp3-button bp3-intent-primary bp3-align-left' href="tutorial/interno">
                    <b>Regras de Uso</b>
                  </a>}
                  { !role.is_internal && <a className='bp3-button bp3-intent-primary bp3-align-left' href="tutorial/como-usar">
                    <b>Regras de Uso</b>
                  </a>}
                </div>
              </div>
            </div>
          </section>
          { role.is_internal && statistics?.rb?.investigations &&
          <section className="HomeScreen__section">
            <div className="HomeScreen__section__content">
              <h1 className="HomeScreen__title">
                <FormattedMessage
                  id="home.stats.title"
                  defaultMessage="Get started exploring public data"
                />
              </h1>
              <div className="HomeScreen__thirds">
                <div>
                  <Statistics
                    styleType="dark"
                    headline={intl.formatMessage(messages.count_investigations)
                    }
                    statistic={statistics.rb.investigations}
                    isPending={statistics.isPending}
                    itemLink={(value) => ({
                      pathname: 'investigations',
                      search: queryString.stringify({
                        'collectionsq': value,
                      }),
                    })}
                    itemLabel={(name) => name}
                  />
                </div>
              </div>
            </div>
          </section> }
        </div>
      </Screen>
    );
  }
}

const mapStateToProps = (state) => ({
  statistics: selectStatistics(state),
  session: selectSession(state),
  metadata: selectMetadata(state),
  role: selectCurrentRole(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps, { fetchStatistics }),
  injectIntl
)(HomeScreen);
