import React, { Component } from 'react';
import { Alert, Callout, Dialog, Intent } from '@blueprintjs/core';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withRouter from 'app/withRouter';
import { resetPasswordUser } from '../../actions';
import ClipboardInput from '../../components/common/ClipboardInput';

import './UserResetPasswordDialog.scss';

const messages = defineMessages({
  button_confirm: {
    id: 'user.reset_password.confirm',
    defaultMessage: 'Create New Password'
  },
  button_cancel: {
    id: 'user.reset_password.cancel',
    defaultMessage: 'Cancel'
  },
  title: {
    id: 'user.reset_password.title',
    defaultMessage: 'New Password'
  },
  description: {
    id: 'user.reset_password.description',
    defaultMessage: 'User password changed successfully!'
  },
});

class UserDeleteDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasChangedPassword: false,
      password: null
    };

    this.onResetPassword = this.onResetPassword.bind(this);
    this.onPasswordCopied = this.onPasswordCopied.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  async onResetPassword() {
    const { user } = this.props;
    const data = await this.props.resetPasswordUser(user);
    this.setState(() => ({ hasChangedPassword: true, password: data.password }));
  }

  onPasswordCopied() {
    this.resetState();
    this.props.toggleDialog();
  }

  resetState() {
    this.setState(() => ({
      hasChangedPassword: false,
      password: null
    }));
  }

  render() {
    const { user, intl } = this.props;
    return (
      <>
        {!this.state.hasChangedPassword ? <Alert
          isOpen={this.props.isOpen}
          icon='warning-sign'
          intent={Intent.WARNING}
          cancelButtonText={intl.formatMessage(messages.button_cancel)}
          confirmButtonText={intl.formatMessage(messages.button_confirm)}
          onCancel={this.props.toggleDialog}
          onConfirm={!this.state.hasChangedPassword ? this.onResetPassword : this.onPasswordCopied}
        >
          <FormattedMessage
            id='user.reset_password.question'
            defaultMessage="Are you sure you want to change {userName} password? It can't be undone."
            values={{
              userName: user.name
            }}
          />
        </Alert> : <Dialog
          className='AuthenticationScreen'
          isOpen={this.props.isOpen}
          onClose={this.onPasswordCopied}
          title={
            intl.formatMessage(messages.title)
          }
        >
          <Callout
            intent={Intent.SUCCESS}
            icon='tick'
            title={
              intl.formatMessage(messages.description)
            }
          >
            <br />
            <FormattedMessage
              id='user.reset_password.message'
              defaultMessage="Copy {userName}'s password before close this window. It won't be showed again."
              values={{
                userName: user.name
              }}
            />
          </Callout>
          <ClipboardInput className={'UserResetPasswordDialog__password'} icon="key" value={this.state.password} />
        </Dialog>
        }
      </>

    )
      ;
  }
}

const mapDispatchToProps = { resetPasswordUser };

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  injectIntl
)(UserDeleteDialog);
