import React from 'react';
import Screen from 'components/Screen/Screen';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import './TutorialScreen.scss';
import { selectCurrentRole, selectMetadata, selectSession, selectStatistics } from '../../selectors';
import { compose } from 'redux';
import withRouter from '../../app/withRouter';
import { connect } from 'react-redux';
import { fetchStatistics } from '../../actions';
import { injectIntl } from 'react-intl';
import { HomeScreen } from '../HomeScreen/HomeScreen';

export class TutorialInternoScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { metadata } = this.props;
    const appTutorial = metadata.pages.find((page) => page.short === 'Tutorial Interno');

    return (
      <Screen
        title={appTutorial.title}
        className='TutorialScreen'
        requireSession
      >
        {appTutorial?.content && (
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {appTutorial.content}
          </ReactMarkdown>
        )}
      </Screen>
    );
  }
}

const mapStateToProps = (state) => ({
  metadata: selectMetadata(state),
});

export default compose(
  connect(mapStateToProps, { }),
)(TutorialInternoScreen);