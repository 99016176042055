import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { Skeleton } from 'components/common';
import { connect } from 'react-redux';
import { selectLocale } from 'selectors';

class EventPeriodSelectionItem extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {};

    this.format = this.format.bind(this);
  }

  format(period) {
    const year = parseInt(period.slice(0, 4));
    const month = parseInt(period.slice(-2));

    const event = new Date();
    event.setFullYear(year, month - 1);

    const fullName = event.toLocaleString(this.props.locale.replace('_', '-'),
      { year: 'numeric', month: 'long' });

    return fullName.charAt(0).toUpperCase() + fullName.slice(1);
  }

  renderSkeleton = () => (
    <option>
      <Skeleton.Text type='span' length={20} />
    </option>
  );

  render() {
    const { period, key, isPending, selected } = this.props;

    if (isPending || !!!period) {
      return this.renderSkeleton();
    }

    return (
      <option key={key} value={period} selected={selected}>
        {this.format(period)}
      </option>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: selectLocale(state)
  };
};

EventPeriodSelectionItem = injectIntl(EventPeriodSelectionItem);
EventPeriodSelectionItem = connect(mapStateToProps, {})(EventPeriodSelectionItem);
export default EventPeriodSelectionItem;
