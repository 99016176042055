import { endpoint } from 'app/api';
import asyncActionCreator from './asyncActionCreator';
import { queryEndpoint } from './util';

export const queryEvents = asyncActionCreator(
  (query) => async () => queryEndpoint(query),
  { name: 'QUERY_EVENTS' }
);

export const queryEventPeriods = asyncActionCreator(
  (query) => async () => {
    const response = await endpoint.get('events/periods');
    return { ...query, result: response.data };
  },
  { name: 'QUERY_EVENT_PERIODS' }
);
