import React, { Component } from 'react';
import { Callout, Classes, Dialog, Intent } from '@blueprintjs/core';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { PasswordAdminSignup } from 'components/auth/PasswordAuth';
import { endpoint } from 'app/api';

import './CreateUserDialog.scss';
import withRouter from '../../app/withRouter';
import { showResponseToast } from '../../app/toast';
import ClipboardInput from '../../components/common/ClipboardInput';

const messages = defineMessages({
  title: {
    id: 'user.new_user',
    defaultMessage: 'New User'
  }
});

/* eslint-disable */

class CreateUserDialog extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      submitted: false,
      pwd: '',
      toggle: this.props.toggleDialog
    };

    this.state = this.initialState;

    this.onSignup = this.onSignup.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  onSignup(data) {
    const { intl, navigate } = this.props;
    endpoint
      .post('/users', data)
      .then((response) => {
        this.setState({
          submitted: true, user: response.data, toggle: () => {
            this.props.toggleDialog();
            navigate(0);
          }
        });
      })
      .catch((e) => {
        showResponseToast(e.response, intl);
      });
  }

  resetState() {
    this.setState(this.initialState);
  }

  render() {
    const { intl, isOpen } = this.props;
    const { submitted, toggle, user } = this.state;

    if (!isOpen) {
      return null;
    }

    return (
      <Dialog
        className='AuthenticationScreen'
        isOpen={isOpen}
        onClose={toggle}
        onOpening={this.resetState}
        title={
          intl.formatMessage(messages.title)
        }
      >
        <div className={Classes.DIALOG_BODY}>
          <section>
            {submitted ? (
              <>
                <Callout
                  intent={Intent.SUCCESS}
                  icon='tick'
                  title={
                    <FormattedMessage
                      id='signup.admin.title'
                      defaultMessage='User created successfully!'
                    />
                  }
                >
                  <FormattedMessage
                    id='signup.admin.password'
                    defaultMessage="Copy {userName}'s password before close this window. It won't be showed again."
                    values={{
                      userName: user.name
                    }}
                  />
                </Callout>
                <ClipboardInput className={'CreateUserDialog__password'} icon="key" value={user.password} />
              </>
            ) : (
              <span>
                <PasswordAdminSignup
                  buttonClassName='signin-button'
                  onSubmit={this.onSignup}
                  intl={intl}
                />
              </span>
            )}
          </section>
        </div>
      </Dialog>
    );
  }
}

export default compose(
  withRouter,
  injectIntl
)(
  CreateUserDialog
);
