import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import withRouter from 'app/withRouter';
import { selectCurrentRole, selectUsersResult } from 'selectors';
import { ErrorSection, QueryInfiniteLoad } from 'components/common';
import UserIndexItem from './UserIndexItem';

import './UserIndex.scss';
import { usersQuery } from '../../queries';
import QueryTags from '../QueryTags/QueryTags';
import { queryUsers } from '../../actions';

export class UserIndex extends Component {
  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);
    this.updateQuery = this.updateQuery.bind(this);
  }

  onSearch(queryText) {
    const { query } = this.props;
    const newQuery = query.set('q', queryText);
    this.updateQuery(newQuery);
  }

  updateQuery(newQuery) {
    const { navigate, location } = this.props;

    navigate({
      pathname: location.pathname,
      search: newQuery.toLocation(),
    });
  }

  renderErrors() {
    const { emptyText, icon, noResultsText, query, result } = this.props;
    const hasQuery = query.hasQuery() || query.hasFilter('creator_id');

    if (result.isError) {
      return <ErrorSection error={result.error} />;
    }
    if (result.total === 0) {
      const message = hasQuery ? noResultsText : emptyText;

      return <ErrorSection icon={icon} title={message} />;
    }

    return null;
  }

  renderResults() {
    const { result } = this.props;
    const skeletonItems = [...Array(10).keys()];

    return (
      <ul className="index">
        {result.results.map((res) => (
          <UserIndexItem key={res.id} user={res} />
        ))}
        {result.isPending &&
          skeletonItems.map((item) => (
            <UserIndexItem key={item} isPending />
          ))}
      </ul>
    );
  }

  render() {
    const {
      query,
      result,
      showQueryTags,
    } = this.props;

    return (
      <div className="UserIndex">
        <div className="UserIndex__controls">
          {showQueryTags && (
            <QueryTags query={query} updateQuery={this.updateQuery} />
          )}
        </div>
        {this.renderErrors()}
        {this.renderResults()}
        <QueryInfiniteLoad
          query={query}
          result={result}
          fetch={this.props.queryUsers}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const query = usersQuery(location);

  return {
    result: selectUsersResult(state, query),
    role: selectCurrentRole(state),
    query,
  };
};

UserIndex = injectIntl(UserIndex);
UserIndex = connect(mapStateToProps, { queryUsers })(UserIndex);
UserIndex = withRouter(UserIndex);
export default UserIndex;
