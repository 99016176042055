import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import withRouter from 'app/withRouter';
import { selectEventsResult } from 'selectors';
import { ErrorSection, QueryInfiniteLoad } from 'components/common';
import EventPeriodSelectionItem from './EventPeriodSelectionItem';

import './EventPeriodSelection.scss';
import { eventPeriodsQuery } from '../../queries';
import { queryEventPeriods } from '../../actions';

export class EventPeriodSelection extends Component {
  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);
    this.updateQuery = this.updateQuery.bind(this);
  }

  onSearch(queryText) {
    const { query } = this.props;
    const newQuery = query.set('q', queryText);
    this.updateQuery(newQuery);
  }

  updateQuery(newQuery) {
    const { navigate, location } = this.props;

    navigate({
      pathname: location.pathname,
      search: newQuery.toLocation()
    });
  }

  renderErrors() {
    const { emptyText, icon, noResultsText, query, result } = this.props;
    const hasQuery = query.hasQuery() || query.hasFilter('creator_id');

    if (result.isError) {
      return <ErrorSection error={result.error} />;
    }
    if (result.total === 0) {
      const message = hasQuery ? noResultsText : emptyText;

      return <ErrorSection icon={icon} title={message} />;
    }

    return null;
  }

  renderResults() {
    const { result, selected, onChange } = this.props;
    const skeletonItems = [...Array(10).keys()];

    return (
      <select className='select' onChange={onChange}>
        {result.results.map((res, index) => (
          <EventPeriodSelectionItem key={index} period={res.period} selected={res.period === selected} />
        ))}
        {result.isPending &&
          skeletonItems.map((item) => (
            <EventPeriodSelectionItem key={item} isPending />
          ))}
      </select>
    );
  }

  render() {
    const {
      query,
      result
    } = this.props;

    return (
      <div className='EventPeriodSelection'>
        {this.renderErrors()}
        {this.renderResults()}
        <QueryInfiniteLoad
          query={query}
          result={result}
          fetch={this.props.queryEventPeriods}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const query = eventPeriodsQuery(location);

  return {
    result: selectEventsResult(state, query),
    query
  };
};

EventPeriodSelection = injectIntl(EventPeriodSelection);
EventPeriodSelection = connect(mapStateToProps, { queryEventPeriods })(EventPeriodSelection);
EventPeriodSelection = withRouter(EventPeriodSelection);
export default EventPeriodSelection;
