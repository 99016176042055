import React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import withRouter from 'app/withRouter';
import Screen from 'components/Screen/Screen';
import Dashboard from 'components/Dashboard/Dashboard';
import EventsDownloadButton from 'components/Toolbar/EventsDownloadButton';

import './EventsScreen.scss';
import { EventIndex } from '../../components/EventsIndex/EventIndex';
import EventPeriodSelection from '../../components/EventPeriodsSelection/EventPeriodSelection';

const messages = defineMessages({
  title: {
    id: 'events.title',
    defaultMessage: 'Events'
  },
  download: {
    id: 'events.download',
    defaultMessage: 'Download'
  },
  empty: {
    id: 'events.empty',
    defaultMessage: 'No events have occurred until now.'
  },
  placeholder: {
    id: 'events.placeholder',
    defaultMessage: 'Search events...'
  },
  no_results: {
    id: 'events.no_results',
    defaultMessage: 'No events were found matching this query.'
  }
});

export class EventsScreen extends React.Component {
  constructor(props) {
    super(props);

    const now = new Date(Date.now());
    let month = '' + (now.getMonth() + 1);
    this.state = {
      period: `${now.getFullYear()}${month.padStart(2, '0')}`
    };

    this.onChangePeriod = this.onChangePeriod.bind(this);
  }

  onChangePeriod(event) {
    this.setState({
      period: event.target.value
    });
  }

  render() {
    const { intl } = this.props;
    return (
      <Screen
        title={intl.formatMessage(messages.title)}
        className='EventsScreen'
        requireSession
      >
        <Dashboard>
          <div className='Dashboard__title-container'>
            <h5 className='Dashboard__title'>
              {intl.formatMessage(messages.title)}
            </h5>
            <p className='Dashboard__subheading'>
              <FormattedMessage
                id='events.description'
                defaultMessage='Visualize o log de eventos de download e upload dos usuários no sistema.'
                values={{
                  b: (chunks) => <strong>{chunks}</strong>
                }}
              />
            </p>
            <div className='Dashboard__actions'>
              <div className={'EventsScreen__period'}>
                <span>Período em análise</span>
                <EventPeriodSelection onChange={this.onChangePeriod} selected={this.state.period}/>
              </div>
              <EventsDownloadButton
                icon='download'
                period={this.state.period}
                text={intl.formatMessage(messages.download)}
              />
            </div>
          </div>
          <EventIndex
            icon='change'
            placeholder={intl.formatMessage(messages.placeholder)}
            noResultsText={intl.formatMessage(messages.no_results)}
            emptyText={intl.formatMessage(messages.empty)}
            showQueryTags={true}
            period={this.state.period}
          />
        </Dashboard>
      </Screen>
    );
  }
}

EventsScreen = withRouter(EventsScreen);
EventsScreen = injectIntl(EventsScreen);
export default EventsScreen;
