import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { Date, Skeleton } from 'components/common';
import { Icon } from '@blueprintjs/core';

class EventIndexItem extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {};

    this.getIcon = this.getIcon.bind(this);
    this.getEventIcon = this.getEventIcon.bind(this);
  }
  getIcon(actor) {
    let icon = 'globe';
    if (actor.is_admin)
      icon = 'shield';
    else if (!actor.is_internal) {
      icon = 'user';
    }

    return (<span>
             <Icon icon={icon} />
          </span>);
  }

  getEventIcon(event) {
    return (<span>
             <Icon icon={event.toLowerCase()} />
          </span>);
  }

  renderSkeleton = () => (
    <tr>
      <td>
        <Skeleton.Text type='span' length={20} />
      </td>
      <td>
        <Skeleton.Text type='span' length={20} />
      </td>
      <td>
        <Skeleton.Text type='span' length={20} />
      </td>
      <td>
        <Skeleton.Text type='span' length={20} />
      </td>
      <td>
        <Skeleton.Text type='span' length={20} />
      </td>
    </tr>
  );

  render() {
    const { event, isPending } = this.props;

    if (isPending || !!!event) {
      return this.renderSkeleton();
    }

    return (
      <tr key={event.id}>
        <td>
          <Date value={event.created_at} showTime={true} />
        </td>
        <td>
          {this.getEventIcon(event.event)}
          {event.event}
        </td>
        <td>
          <a rel='noreferrer' href={`investigations/${event.collection.collection_id}`}
             target='_blank'>{event.collection.label}</a>
        </td>
        <td>
          { !!event.document?.id && <a rel='noreferrer' href={`entities/${event.document.id}`}
              target='_blank'>{event.filename}</a>}
          { !!!event.document && !!event.filename && <span>{event.filename}</span>}
        </td>
        <td>
          {this.getIcon(event.actor)}
          <span>
              {!!event.actor && <>
                  {event.actor.name}<br />
          <a rel='noreferrer' href={`mailto://${event.actor.email}`} target='_blank'>{`<${event.actor.email}>`}</a>
              </>}
              {!event.actor && <i>Usuário removido do sistema</i>}
            </span>
        </td>
      </tr>
    );
  }
}

EventIndexItem = injectIntl(EventIndexItem);
export default EventIndexItem;
