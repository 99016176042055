import { endpoint } from 'app/api';
import asyncActionCreator from './asyncActionCreator';

export const queryUsers = asyncActionCreator(
  (query) => async () => {
    const response = await endpoint.get('users');
    return { ...query, result: response.data };
  },
  { name: 'QUERY_USERS' }
);

export const deleteUser = asyncActionCreator(
  (user) => async () => {
    const config = { params: { sync: false } };
    await endpoint.delete(`users/${user.id}`, config);
    return { id: user.id };
  },
  { name: 'DELETE_USER' }
);

export const resetPasswordUser = asyncActionCreator(
  (user) => async () => {
    const config = { params: { sync: false } };
    const response = await endpoint.post(`users/${user.id}/reset_password`, config);
    return response.data;
  },
  { name: 'RESET_PASSWORD_USER' }
);

export const editAdminUser = asyncActionCreator(
  (user) => async () => {
    const response = await endpoint.put(`users/${user.id}/admin`, user);
    return response.data;
  },
  { name: 'RESET_PASSWORD_USER' }
);