import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import withRouter from 'app/withRouter';
import { selectEventsResult } from 'selectors';
import { ErrorSection, QueryInfiniteLoad } from 'components/common';
import EventIndexItem from './EventIndexItem';

import './EventIndex.scss';
import { eventsQuery } from '../../queries';
import { queryEvents } from '../../actions';

export class EventIndex extends Component {
  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);
    this.updateQuery = this.updateQuery.bind(this);
  }

  onSearch(queryText) {
    const { query } = this.props;
    const newQuery = query.set('q', queryText);
    this.updateQuery(newQuery);
  }

  updateQuery(newQuery) {
    const { navigate, location } = this.props;

    navigate({
      pathname: location.pathname,
      search: newQuery.toLocation(),
    });
  }

  renderData() {
    const { emptyText, icon, noResultsText, query, result } = this.props;
    const hasQuery = query.hasQuery() || query.hasFilter('creator_id');

    if (result.isError) {
      return <ErrorSection error={result.error} />;
    }
    if (result.total === 0) {
      const message = hasQuery ? noResultsText : emptyText;

      return <ErrorSection icon={icon} title={message} />;
    }

    return this.renderResults();
  }

  renderResults() {
    const { result } = this.props;
    const skeletonItems = [...Array(10).keys()];

    return (
      <table className="EventTable">
        <thead>
        <tr>
          <th>
            <FormattedMessage
              id='event.labels.created_at'
              defaultMessage='Creation date'
            />
          </th>
          <th>
            <FormattedMessage
              id='event.labels.event'
              defaultMessage='Event'
            />
          </th>
          <th>
            <FormattedMessage
              id='event.labels.collection'
              defaultMessage='Collection'
            />
          </th>
          <th>
            <FormattedMessage
              id='event.labels.document'
              defaultMessage='Document'
            />
          </th>
          <th>
            <FormattedMessage
              id='event.labels.user'
              defaultMessage='User'
            />
          </th>
        </tr>
        </thead>
        <tbody>
        {result.results.map((res) => (
          <EventIndexItem key={res.id} event={res} />
        ))}
        {result.isPending &&
          skeletonItems.map((item) => (
            <EventIndexItem key={item} isPending />
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    const {
      query,
      result
    } = this.props;

    return (
      <div className="EventIndex">
        {this.renderData()}
        <QueryInfiniteLoad
          query={query}
          result={result}
          fetch={this.props.queryEvents}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { location, period } = ownProps;
  const query = eventsQuery(location)
    .defaultSortBy('created_at', 'desc')
    .setFilter('period', period);

  return {
    result: selectEventsResult(state, query),
    query,
    period
  };
};

EventIndex = injectIntl(EventIndex);
EventIndex = connect(mapStateToProps, { queryEvents })(EventIndex);
EventIndex = withRouter(EventIndex);
export default EventIndex;
