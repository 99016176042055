import React, { Component } from 'react';
import { Alert, Intent } from '@blueprintjs/core';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withRouter from 'app/withRouter';
import { editAdminUser } from '../../actions';

const messages = defineMessages({
  button_confirm: {
    id: 'user.delete.confirm',
    defaultMessage: 'Confirmar'
  },
  button_cancel: {
    id: 'user.delete.cancel',
    defaultMessage: 'Cancelar'
  }
});

class UserEditAdminDialog extends Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
  }

  async onEdit() {
    const { user, navigate } = this.props;
    user.is_admin = !user.is_admin;
    await this.props.editAdminUser(user);
    navigate(0);
  }

  render() {
    const { user, intl } = this.props;
    return (
      <Alert
        isOpen={this.props.isOpen}
        icon={!user.is_admin ? 'badge' : 'following'}
        intent={Intent.WARNING}
        cancelButtonText={intl.formatMessage(messages.button_cancel)}
        confirmButtonText={intl.formatMessage(messages.button_confirm)}
        onCancel={this.props.toggleDialog}
        onConfirm={this.onEdit}
      >
        { !user.is_admin ? <FormattedMessage
          id='user.promote_admin.question'
          defaultMessage='Are you sure you want to promote {userLabel} to Admin?'
          values={{
            userLabel: user.name
          }}
        /> : <FormattedMessage
          id='user.revoke_admin.question'
          defaultMessage='Are you sure you want to revoke Admin privileges from {userLabel}?'
          values={{
            userLabel: user.name
          }}
        />
        }
      </Alert>
    );
  }
}

const mapDispatchToProps = { editAdminUser };

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  injectIntl
)(UserEditAdminDialog);
