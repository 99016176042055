import React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import withRouter from 'app/withRouter';
import Screen from 'components/Screen/Screen';
import Dashboard from 'components/Dashboard/Dashboard';
import { updateRole } from 'actions';
import { selectCurrentRole } from 'selectors';
import UserCreateButton from 'components/Toolbar/UserCreateButton';

import './UsersScreen.scss';
import { UserIndex } from '../../components/UserIndex/UserIndex';

const messages = defineMessages({
  title: {
    id: 'users.title',
    defaultMessage: 'Users'
  },
  create: {
    id: 'users.create',
    defaultMessage: 'New User'
  },
  empty: {
    id: 'cases.empty',
    defaultMessage: 'You do not have any investigations yet.'
  },
  placeholder: {
    id: 'cases.placeholder',
    defaultMessage: 'Search investigations...'
  },
  no_results: {
    id: 'cases.no_results',
    defaultMessage: 'No investigations were found matching this query.'
  }
});

export class UsersScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: props.role
    };
  }

  render() {
    const { intl } = this.props;
    return (
      <Screen
        title={intl.formatMessage(messages.title)}
        className='UsersScreen'
        requireSession
      >
        <Dashboard>
          <div className='Dashboard__title-container'>
            <h5 className='Dashboard__title'>
              {intl.formatMessage(messages.title)}
            </h5>
            <p className='Dashboard__subheading'>
              <FormattedMessage
                id='users.description'
                defaultMessage='Usuários são categorizados como internos ou externos. Usuários internos são todos os usuários com e-mail <b>@reporterbrasil.org.br</b>. Qualquer outro domínio de e-mail será considerado um usuário externo.'
                values={{
                  b: (chunks) => <strong>{chunks}</strong>
                }}
              />
            </p>
            <div className='Dashboard__actions'>
              <UserCreateButton
                icon='new-person'
                text={intl.formatMessage(messages.create)}
              />
            </div>
          </div>
          <UserIndex
            icon='people'
            placeholder={intl.formatMessage(messages.placeholder)}
            noResultsText={intl.formatMessage(messages.no_results)}
            emptyText={intl.formatMessage(messages.empty)}
          />
        </Dashboard>
      </Screen>
    );
  }
}

const mapStateToProps = (state) => ({
  role: {
    ...selectCurrentRole(state)
  }
});

UsersScreen = withRouter(UsersScreen);
UsersScreen = connect(mapStateToProps, { updateRole })(UsersScreen);
UsersScreen = injectIntl(UsersScreen);
export default UsersScreen;
